import * as yup from 'yup';
import i18n from 'i18next';

yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    required: i18n.t('required field'),
  },
  string: {
    email: i18n.t('this field must be an email'),
  },
  // use functions to generate an error object that includes the value from the schema
});

export const AdminsManagementCreateSchema = yup
  .object({
    name: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().email().required(),
    main_id: yup.number().typeError(i18n.t('required field')).required(),
    password: yup.string().min(6, 'must be more then 6 charterer').required(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], i18n.t('password must match'))
      .required(),
  })
  .required();

export const AdminsManagementUpdateSchema = yup
  .object({
    name: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().email().required(),
    status: yup.string().required(),
    main_id: yup.number().typeError(i18n.t('required field')).required(),
  })
  .required();

export const UserManagementCreateSchema = yup
  .object({
    name: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().email().required(),
    main_id: yup.number().typeError(i18n.t('required field')).required(),
    sub_id: yup.number().typeError(i18n.t('required field')).required(),
    password: yup.string().min(6, 'must be more then 6 charterer').required(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], i18n.t('password must match'))
      .required(),
  })
  .required();

export const UserManagementUpdateSchema = yup
  .object({
    name: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().email().required(),
    status: yup.string().required(),
    main_id: yup.number().typeError(i18n.t('required field')).required(),
    sub_id: yup.number().typeError(i18n.t('required field')).required(),
  })
  .required();

export const LoginAdminSchema = yup
  .object({
    // email: yup.string().email('this field must be an email').required(),
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

export const LoginUserSchema = yup
  .object({
    // email: yup.string().email('this field must be an email').required(),
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

export const UpdatePasswordSchema = yup
  .object({
    current_password: yup.string().required(),
    password: yup.string().min(6, 'must be more then 6 charterer').required(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], i18n.t('password must match'))
      .required(),
  })
  .required();

export const MainAdministrationSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export const SubAdministrationSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export const FileExportSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export const FileReceivedSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export const ExportSchema = yup
  .object({
    serial_number: yup.number().typeError(i18n.t('required field')).min(1).required(),
    export_at: yup.date().required(),
    subSender: yup
      .object()
      .shape({
        id: yup.number().required(),
        main_id: yup.number().required(),
        name: yup.string().required(),
      })
      .nullable()
      .required(),
    // mainRecipient: yup.object().shape({
    //   id: yup.number().required(),
    //   name: yup.string().required(),
    // }),

      reference_serial_number: yup.string().nullable(),
      reference_year: yup.string().when('reference_serial_number', {
          is: (value) => value,
          then: yup
              .string()
              .min(4,i18n.t( 'this field must be year')).max(4, i18n.t( 'this field must be year')).typeError(i18n.t('required field')).required(),
          otherwise: yup.string().nullable(),
      }),

    mainRecipient: yup.object().when('outMain', {
      is: (value) => value.length <= 0,
      then: yup
        .object()
        .shape({
          id: yup.number().required(),
          name: yup.string().required(),
        })
        .nullable()
        .required(),
      otherwise: yup.object().nullable(),
    }),

    subRecipient: yup.array().when('mainRecipient', {
      is: (value) => value,
      then: yup
        .array()
        .min(1, 'required-field')
        .of(
          yup.object().shape({
            id: yup.number().required(),
            main_administration: yup.object().shape({
              id: yup.number().required(),
              name: yup.string().required(),
            }),
            main_id: yup.number().required(),
            name: yup.string().required(),
          })
        ),
      otherwise: yup.array(),
    }),

    // subRecipient: yup.array().of(
    //   yup.object().shape({
    //     id: yup.number().required(),
    //     main_administration: yup.object().shape({
    //       id: yup.number().required(),
    //       name: yup.string().required(),
    //     }),
    //     main_id: yup.number().required(),
    //     name: yup.string().required(),
    //   })
    // ),
    outMain: yup.array(),
    subject: yup.string().required(),
    file_id: yup
      .object()
      .shape({
        id: yup.number().required(),
        main_id: yup.number().required(),
        name: yup.string().required(),
      })
      .nullable()
      .required(),
    notes: yup.string().nullable(),
    images: yup.array().min(1, i18n.t('required field')),
  })
  .required();

export const ReceivedSchema = yup
  .object({
    serial_number: yup.number().typeError(i18n.t('required field')).min(1).required(),
    referrer_number: yup.string().required(),
    received_at: yup.date().required(),
    sender: yup.string().required(),

    subRecipient: yup
      .array()
      .min(1, i18n.t('required field'))
      .of(
        yup.object().shape({
          id: yup.number().required(),
          main_administration: yup.object().shape({
            id: yup.number().required(),
            name: yup.string().required(),
          }),
          main_id: yup.number().required(),
          name: yup.string().required(),
        })
      ),
    subject: yup.string().required(),
    file_id: yup
      .object()
      .shape({
        id: yup.number().required(),
        main_id: yup.number().required(),
        name: yup.string().required(),
      })
      .nullable()
      .required(),
    notes: yup.string().nullable(),
    images: yup.array().min(1, i18n.t('required field')),
  })
  .required();

export const InternalReceivingUpdateSchema = yup
  .object({
    received_serial_number: yup.number().typeError(i18n.t('required field')).min(1).required(),
    export_received_at: yup.date().required(),
  })
  .required();
