import { createSlice } from '@reduxjs/toolkit';
import { getImagesReceived } from '../../actions/admin/receivedAction';
import { getImagesExport,getImagesBySerialNumber } from '../../actions/admin/exportAction';

const initialState = {
  img: [],
  images: [],
  imgReceived: [],
  loading: false,
  error: null,
};

const scanSlice = createSlice({
  name: 'scan ',
  initialState,
  reducers: {
    startScan: (state) => {
      state.loading = true;
    },
    endScan: (state) => {
      state.loading = false;
    },

    pushImg: (state, action) => {
      state.img.push(action.payload);
    },
    addImages: (state, action) => {
      state.img = action.payload;
    },
    editImg: (state, action) => {
      state.img = state.img.map((el, index) => (index === action.payload.index ? action.payload.item : el));
    },
    deleteImg: (state, action) => {
      state.img = state.img.filter((_, index) => index !== action.payload);
    },
    clearImg: (state) => {
      state.img = [];
    },

    pushImgReceived: (state, action) => {
      state.imgReceived.push(action.payload);
    },
    deleteImgReceived: (state, action) => {
      state.imgReceived = state.imgReceived.filter((_, index) => index !== action.payload);
    },
    clearImgReceived: (state) => {
      state.imgReceived = [];
    },
  },
  extraReducers: {
    // get All image export by id
    [getImagesExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getImagesExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.images = action.payload.map((image) => image.image_url);
    },
    [getImagesExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.images = [];
    },

    [getImagesBySerialNumber.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getImagesBySerialNumber.fulfilled]: (state, action) => {
      state.loading = false;
      state.images = action.payload.map((image) => image.image_url);
    },
    [getImagesBySerialNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.images = [];
    },

    // get All image Received by id
    [getImagesReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getImagesReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.images = action.payload.map((image) => image.image_url);
    },
    [getImagesReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.images = [];
    },
  },
});
export const {
  startScan,
  endScan,
  pushImg,
  addImages,
  editImg,
  deleteImg,
  clearImg,
  pushImgReceived,
  deleteImgReceived,
  clearImgReceived,
} = scanSlice.actions;
export default scanSlice.reducer;
